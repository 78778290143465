<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <div>
          <h4 class="mr-sm-4 header-tablepage">PRODUCT DETAIL</h4>
        </div>
        <div class="mt-3">
          <b-tabs v-model="tabIndex">
            <b-tab title="Information">
              <TabProductDetailPanel
                :form="form"
                :productType="productType"
                :ah1="ah1"
                @searchAH2="searchAH2"
                :ah2="ah2"
                @searchAH3="searchAH3"
                :ah3="ah3"
                @searchAH4="searchAH4"
                :ah4="ah4"
                :isLoadingAh1="isLoadingAh1"
                :isLoadingAh2="isLoadingAh2"
                :isLoadingAh3="isLoadingAh3"
                :isLoadingAh4="isLoadingAh4"
                @getAH4="getAH4"
                :v="$v"
                @searchAH1ByKeyword="getCateProductAh1"
                @searchAH2ByKeyword="searchAH2ByKeyword"
                @searchAH3ByKeyword="searchAH3ByKeyword"
                @searchAH4ByKeyword="searchAH4ByKeyword"
                :id="parseInt(id)"
              />
            </b-tab>
            <b-tab title="Image">
              <TabImageProductDetailPanel
                :form="form"
                :v="$v"
                :id="parseInt(id)"
              />
            </b-tab>
          </b-tabs>
        </div>
        <FooterAction @submit="sendForm" routePath="/product" />
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import TabProductDetailPanel from "@/components/productPromotion/detail/TabProductDetailPanel";
import TabImageProductDetailPanel from "@/components/productPromotion/detail/TabImageProductDetailPanel";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    OtherLoading,
    TabProductDetailPanel,
    TabImageProductDetailPanel,
  },
  data() {
    return {
      isLoading: false,
      form: {
        name: "",
        price: 0,
        status: 0,
        ah_4_id: "",
        article_no: "",
        article_type: "ZFIN",
        barcode: "",
        description: "",
        admin_user_guid: "",
        image_url: null,
      },
      productType: [
        {
          id: "ZFIN",
          name: "ZFIN",
        },
        {
          id: "ZSAM",
          name: "ZSAM",
        },
        {
          id: "ZLPR",
          name: "ZLPR",
        },
      ],
      ah1: [],
      ah2: [],
      ah3: [],
      ah4: [],
      isLoadingAh1: false,
      isLoadingAh2: false,
      isLoadingAh3: false,
      isLoadingAh4: false,
      ah4_id: 0,
      ah1_id: 0,
      ah2_id: 0,
      ah3_id: 0,
      tabIndex: 0,
      seachKeywordAH2: "",
      seachKeywordAH3: "",
      seachKeywordAH4: "",
      id: this.$route.params.id,
    };
  },
  validations: {
    form: {
      name: { required },
      price: { required },
      article_no: { required },
      article_type: { required },
      image_url: { required },
      ah_4_id: { required },
      barcode: { required },
    },
  },
  async created() {
    await this.getCateProductAh1("");
    if (parseInt(this.id) !== 0) {
      this.isLoading = true;
      await this.getProductDetail();
    }
  },
  methods: {
    async getCateProductAh1(search) {
      this.isLoadingAh1 = true;
      let body = {
        search: search,
      };
      await this.$store.dispatch("getCategoryProductAh1", body);
      const data = this.$store.state.product.stateCategoryProductAh1;
      if (data.result === 1) {
        this.ah1 = data.detail;
        this.isLoadingAh1 = false;
      }
    },
    getAH4(val) {
      this.form.ah_4_id = val;
    },
    async sendForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        if (this.$v.form.image_url.$error || this.$v.form.image_url.$invalid) {
          console.log(this.$v.form.image_url);
          this.tabIndex = 2;
        }
        return;
      }
      this.isLoading = true;
      this.form.admin_user_guid = this.$cookies.get(
        "back_office_admin_user_guid"
      );

      if (parseInt(this.id) === 0) {
        await this.$store.dispatch("createProductPromotion", this.form);
        const data = this.$store.state.product.statecreateProductPromotion;
        if (data.result === 1) {
          this.successAlert().then(() => this.$router.push("/product"));
        } else {
          this.errorAlert(data.message);
        }
        this.isLoading = false;
      } else {
        await this.$store.dispatch("updateProductPromotion", this.form);
        const data = this.$store.state.product.stateUpdateProductPromotion;
        if (data.result === 1) {
          this.successAlert().then(() => this.$router.push("/product"));
        } else {
          this.errorAlert(data.message);
        }
        this.isLoading = false;
      }
    },

    async getProductDetail() {
      await this.$store.dispatch("getProductPromotionDetail", this.id);
      const data = this.$store.state.product.stateProductPromotionDetail;
      if (data.result === 1) {
        this.form = data.detail;
        this.ah1_id = this.form.ah_1_id;
        this.ah2_id = this.form.ah_2_id;
        this.ah3_id = this.form.ah_3_id;
        this.ah4_id = this.form.ah_4_id;
        await this.getCategoryAH2();
        await this.getCategoryAH3();
        await this.getCategoryAH4();
        this.isLoading = false;
      }
    },
    deleteData() {},

    searchAH2ByKeyword(search) {
      this.seachKeywordAH2 = search;
      this.getCategoryAH2();
    },
    searchAH2(ah1) {
      this.ah2 = [];
      this.ah3 = [];
      this.ah4 = [];
      this.ah1_id = ah1;
      this.getCategoryAH2();
    },
    async getCategoryAH2() {
      this.isLoadingAh2 = true;
      let body = {
        search: this.seachKeywordAH2,
        ah1_id: this.ah1_id,
      };
      await this.$store.dispatch("getCategoryProductAh2", body);
      const data = this.$store.state.product.stateCategoryProductAh2;
      if (data.result === 1) {
        this.ah2 = data.detail;
        this.isLoadingAh2 = false;
      }
    },
    searchAH3ByKeyword(val) {
      this.seachKeywordAH3 = val;
      this.getCategoryAH3();
    },
    async searchAH3(id) {
      this.ah3 = [];
      this.ah4 = [];
      this.ah1_id = id.ah1;
      this.ah2_id = id.ah2;
      this.getCategoryAH3();
    },
    async getCategoryAH3() {
      this.isLoadingAh3 = true;
      let body = {
        search: this.seachKeywordAH3,
        ah1_id: this.ah1_id,
        ah2_id: this.ah2_id,
      };
      await this.$store.dispatch("getCategoryProductAh3", body);
      const data = this.$store.state.product.stateCategoryProductAh3;
      if (data.result === 1) {
        this.ah3 = data.detail;
        this.isLoadingAh3 = false;
      }
    },
    searchAH4ByKeyword(val) {
      this.seachKeywordAH4 = val;
      this.getCategoryAH4();
    },
    searchAH4(id) {
      this.ah4 = [];
      this.ah1_id = id.ah1;
      this.ah2_id = id.ah2;
      this.ah3_id = id.ah3;
      this.getCategoryAH4();
    },
    async getCategoryAH4() {
      this.isLoadingAh4 = true;
      let body = {
        search: this.seachKeywordAH4,
        ah1_id: this.ah1_id,
        ah2_id: this.ah2_id,
        ah3_id: this.ah3_id,
      };
      await this.$store.dispatch("getCategoryProductAh4", body);
      const data = this.$store.state.product.stateCategoryProductAh4;
      if (data.result === 1) {
        this.ah4 = data.detail;
        this.isLoadingAh4 = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-template {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: transparent;
  border-radius: 0px;
  width: 30%;
}
.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.btn-cancel-automation {
  color: #fff;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
.btn-delete-automation {
  background-color: transparent;
  border: 1px solid #39424e;
  border-radius: 0px;
  width: 30%;
  color: #39424e;
}
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
</style>
